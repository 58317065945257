<template>
	<div>
		
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<el-button type="primary" round icon="el-icon-back" size="mini" @click="goBack()">{{$t('ca5a01a5adf20fe7')}}</el-button>
						<span class="tct_tit">渠道发货地址新增</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <el-button type="success" @click="openDioalog(null, $t('c0246c55b9cac963'))" size="small"
						icon="el-icon-plus">{{ $t('c0246c55b9cac963') }}</el-button>
						
					<el-button @click="delAction($event, null)" type="danger" size="small"
						icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button> -->
		
					<el-button type="success" @click="submitForm()" size="small" icon="el-icon-document">保存</el-button>
		
					<!-- <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button> -->
				</el-col>
			</el-row>
		</div>
		<!-- <el-drawer :wrapperClosable="false" :title="'偏远地区编码配置'" append-to-body :visible.sync="dialogFile" :direction="'rtl'"
			size="800px"> -->
			<div style="padding: 10px;" v-loading="loading">
				<div>
					<span>lgsKeyCode</span>
					<el-select filterable clearable size="small" v-model="form.lgsKeyCode"
						:placeholder="$t('2ad108ab2c560530')" style="width: 200px;" @change="lgsKeyChange()">
						<el-option v-for="(item,index) in selectOption.biz_wh_lgskey_code" :key="index"
							:label="item.logistics+'('+item.code+')'+'('+item.codeName+')'" :value="item.code">
							<div style="display: flex; justify-content: space-between;">
								<div>{{ item.logistics }}({{ item.code }})({{ item.codeName }})</div>
								<div style="color: #8492a6; font-size: 13px">
									<span v-if="0 == item.isUse" style="color: red;">{{$t('i18nn_ab5902ee5c645e01')}}</span>
									<span v-else style="color: #66b1ff;">{{$t('i18nn_3f0ecebbd9d2f23b')}}</span>
								</div>
							</div>
						</el-option>
					</el-select>
				</div>
				

				<CarrShipFromAddrHotTable ref="HotTable" :selectDicOption="{}">
				</CarrShipFromAddrHotTable>

				<!-- <el-form ref="form" :rules="formRules" :model="form" label-width="150px" v-loading="loading">
					
					<el-form-item :label="'country'" prop="">
						<el-input type="text" clearable v-model="form.country"></el-input>
					</el-form-item>
					
					<el-form-item :label="'remoteLevel'" prop="">
						<el-input type="text" clearable v-model="form.remoteLevel"></el-input>
					</el-form-item>

					<el-form-item :label="'zipCode'" prop="">
						<el-input type="text" clearable v-model="form.zipCode"></el-input>
					</el-form-item>
					<el-form-item :label="$t('Storage.tableColumn.remark')">
						<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark"  :maxlength="1000" show-word-limit></el-input>
					</el-form-item>

				</el-form> -->
			</div>

			<!-- <div class="drawer-footer"> -->
				<!-- <el-button type="primary" icon="el-icon-tickets" @click="submitForm()">{{ $t('FormMsg.Save') }}</el-button> -->
				<!-- <el-button type="primary" plain @click="dialogFile = false">{{$t('4e9fc68608c60999')}}</el-button> -->
			<!-- </div> -->
		<!-- </el-drawer> -->

	</div>
</template>
<script>
	import {
		deepClone,
		clearObjectVal
	} from '@/utils/utils.js';

	import {
		getDicData
	} from '@/axios/common.js';

	import CarrShipFromAddrHotTable from './hotTable/CarrShipFromAddrHotTable.vue';
	// import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';

	export default {

		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row: {}
		},
		components: {
			// cusSelFuzzy,
			// whNoSelect
			CarrShipFromAddrHotTable
		},
		data() {
			return {
				// dialogFile: false,

				loading: false,
				// form: {
				// 	"userId":"",
				// 	"thridCode":"",
				// 	"whNo":"",
				// 	"thridWhNo":"",
				// 	"apiUrl":"",
				// 	"clientKey":"",
				// 	"clientSecret":""
				// },
				form:{
					"lgsKeyCode":"",
					"lgsKeyId":""
				// 	"country":"",
				// 	"remoteLevel":"",
				// 	"zipCode":"",
				},

				// formRules: {

				// },

				selectOption: {
					biz_wh_lgskey_code:[]
				},

			};
		},
		// watch: {
		// 	openTime: function(newVal, oldVal) {
		// 		console.log('openTime');
		// 		// this.dialogFile = true;
		// 		this.initData();
		// 	}
		// },
		activated(){
			this.initData();
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			this.getLGSByCodeData();
		},
		methods: {
			initData() {

				this.$nextTick(() => {
					if (this.$refs.HotTable) {
						// this.$refs.HotTable.initHotTable();
						this.$refs.HotTable.clear();
					}

				});

				// // 重置
				// this.resetForm('form');
				// if (!!this.row) {
				// 	this.getDetData(this.row.id);
				// }
			},
			
			lgsKeyChange(){
				let selObj = this.selectOption.biz_wh_lgskey_code.find(item=>{
					return item.code == this.form.lgsKeyCode;
				});
				this.form.lgsKeyId = selObj.id;
			},
			
			goBack() {
				this.$router.go(-1);
			},

			// changCus2(data) {
			// 	console.log('changCus', data);
			// 	this.form.userId = data.userId;
			// },
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.form.whNo = data.code;
			// },

			// getDetData(keyId) {
			// 	this.loading = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhThirdWmsConfigQuery + "/" + keyId, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(data);
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				this.form = data.data;
			// 				this.$nextTick(() => {
			// 					this.$refs.cusSelFuzzy2.init(this.form.userId);
			// 					this.$refs.whNoSelect.init(this.form.whNo);
			// 				});
			// 			} else {
			// 				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.loading = false;
			// 		});
			// },

			//获取数据
			getData() {
				// this.$nextTick(() => {
				let excelData = this.$refs.HotTable.getData();
				// excelData.expressSkuList.forEach((item,index)=>{
				// 	item.expressSkuList.push({

				// 	});
				// });
				// excelData.forEach((item,index)=>{
				// 	item.expressSkuList = [];
				// 	if(!!item.goodsSku1){
				// 		item.expressSkuList.push({
				// 			goodsSku: item.goodsSku1,
				// 			quantity: item.quantity1,
				// 		});
				// 	} 
				// 	if(!!item.goodsSku2){
				// 		item.expressSkuList.push({
				// 			goodsSku: item.goodsSku2,
				// 			quantity: item.quantity2,
				// 		});
				// 	}  
				// 	if(!!item.goodsSku3){
				// 		item.expressSkuList.push({
				// 			goodsSku: item.goodsSku3,
				// 			quantity: item.quantity3,
				// 		});
				// 	} 
				// 	if(!!item.goodsSku4){
				// 		item.expressSkuList.push({
				// 			goodsSku: item.goodsSku4,
				// 			quantity: item.quantity4,
				// 		});
				// 	} 
				// 	if(!!item.goodsSku5){
				// 		item.expressSkuList.push({
				// 			goodsSku: item.goodsSku5,
				// 			quantity: item.quantity5,
				// 		});
				// 	}
				// })
				return excelData;
				// });
			},

			//提交信息
			submitForm() {
				// this.$refs[formName].validate(valid => {
				// 	if (valid) {
				// let formData = Object.assign({}, this.form);
				let formData = this.getData();
				
				let new_formData = formData.map(item=>{
					item.lgsCode = this.form.lgsKeyCode;
					item.lgsId = this.form.lgsKeyId;
					return item;
				})
				
				// if(!!formData.id){
				this.postData(this.$urlConfig.WhCarrierShipFromAddrAdd, new_formData, '', () => {
					// this.dialogFile = false;
					// this.$emit('success');
					this.$message.success("保存成功")
					this.goBack();
				});
				// } else {
				// 	this.postData(this.$urlConfig.WhRemoteAreaCodeConfigAdd, formData, '', () => {
				// 		this.dialogFile = false;
				// 		this.$emit('success');
				// 	});
				// }

				// 	} else {
				// 		console.log('error submit!!');
				// 		this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
				// 			type: 'warning'
				// 		});
				// 		return false;
				// 	}
				// });
			},
			//重置输入框
			// resetForm(formName) {
			// 	console.log(formName);
			// 	if (this.$refs[formName]) {
			// 		this.$refs[formName].resetFields();
			// 		this.form = clearObjectVal(this.form);
			// 	} else {
			// 		console.log('this.$refs[formName]', this.$refs[formName]);
			// 	}

			// 	// this.$nextTick(() => {
			// 	// 	this.$refs.cusSelFuzzy2.init('');
			// 	// 	this.$refs.whNoSelect.init('');
			// 	// });
			// },

			//提交信息
			postData(url, formData, type, callback) {
				this.loading = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					this.loading = false;
					if (200 == data.code) {
						callback();
					} else {
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				});
			},
			
			//lgscode
			getLGSByCodeData() {
				this.loading = true;
				this.$http
					.put(this.$urlConfig.WhPriterOrderUps2LgsList, {
						isUse: '1'
					})
					.then(({
						data
					}) => {
						console.log('查询lgs，请求成功');
						console.log(data);
						this.loading = false;
						if (200 == data.code && data.rows) {
							this.selectOption.biz_wh_lgskey_code = data.rows;
						} else {
							if (!data.msg) {
								data.msg = 'lgs' + this.$t('i18nn_323ee425eca7208c');
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						this.loading = false;
						console.log('查询数据字典接口，请求失败');
						this.$message.error('lgs' + this.$t('i18nn_323ee425eca7208c') + '！');
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>