<template>
	<div style="">
		<div ref="hotTable"></div>
	</div>
</template>

<script>
	import Handsontable from 'handsontable';

	import {
		clearObjectVal
	} from '@/utils/common.js';
	
	import {
		getDicData
	} from '@/axios/common.js';

	import {
		calcHeight,
		settings
	} from '@/utils/hotTableSettings.js';
	
	import {
		columns_config,
		nestedHeadersTop_config
	} from './CarrShipFromAddrHotTable.js';


	let columns = columns_config();

	let option = {
		height: calcHeight(400,200),
	}
	let nestedHeadersTop = nestedHeadersTop_config();
	let hiddenColumns = {
		// columns: [27],
		// indicators: true,
		// exclude hidden columns from copying and pasting
		copyPasteEnabled: false
	};
	let settingData = settings(columns, option, nestedHeadersTop, hiddenColumns);
	let hotTableSettings = settingData.config;
	let DataRowObj = settingData.DataRowObj;

	export default {
		components: {

		},
		props: {
			// selectDicOption:{}
		},
		watch: {

		},
		data() {
			return {
				hotTable: {},
				// plugin: null,
				selectOption: {
					wh_size_unit: [],
					wh_packing_type: [],
					wh_container_size: []
				},
			};
		},
		activated() {

		},
		created() {
			console.log('created');
		},
		mounted() {

			this.$nextTick(() => {
				try {
					this.initHotTable();
					//数据字典
					// getDicData(['wh_packing_type', 'wh_container_size',
					// 	'wh_size_unit'
					// ],
					// 	(data)=>{
					// 		this.selectOption.wh_size_unit = data['wh_size_unit'];
					// 		this.selectOption.wh_packing_type = data['wh_packing_type'];
					// 		this.selectOption.wh_container_size = data['wh_container_size'];
					// 		try {
					// 			this.updateSettingsColumns();
					// 		} catch (e) {
					// 			//TODO handle the exception
					// 			console.log(e);
					// 		}
					// });
				} catch (e) {
					//TODO handle the exception
					console.log(e);
				}
			});
		},
		methods: {
			//初始化HotTable
			initHotTable() {
				let hotElement = this.$refs.hotTable;
				console.log('hotElement', hotElement);

				let hot = new Handsontable(hotElement, hotTableSettings);
				this.hotTable = hot;
				console.log('this.hotTable', this.hotTable);
				this.clear();
			},
			//更新列表配置
			updateSettingsColumns() {
				this.hotTable.updateSettings({
					columns: hotTableSettings.columns
				});
			},
			
			//初始化和加载数据
			loadData(objArr) {
				console.log('loadData', objArr);
				if (objArr.length <= 0) {
					this.clear();
				} else {
					this.hotTable.loadData(objArr);
				}
			},
			//数组转对象
			formatterArrToObj(arr) {
				let reArr = [];
				arr.map((item, index) => {
					let obj = {}
					columns.map((item2, index2) => {
						obj[item2.data] = item[index2]?(item[index2]+'').trim():null;
					});
					reArr.push(obj);

				});
				return reArr;
			},
			//获取数据
			getData() {
				let hotTableData = this.hotTable.getData();
				console.log(hotTableData);
				let data = this.formatterArrToObj(hotTableData);
				console.log(data);
				return data;
			},
			//清空数据
			clear() {
				this.$nextTick(() => {
					console.log('clear', DataRowObj);
					let new_DataRowObj = clearObjectVal(DataRowObj);
					this.hotTable.loadData([new_DataRowObj]);
				});

			},
		}
	}
</script>

<style>
	/* .htSelectEditor {
		display: inline-block !important;
	} */
	.handsontable .htAutocompleteArrow {
		color: #999;
	}
</style>
