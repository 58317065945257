export const columns_config = () => {
	
	// "lgsId":"","lgsCode":"","countryCode":"","addresses1":"","addresses2":"",
	// "city":"","phone":"","provinceCode":"","postalCode":"","addrName":""
	
	return [
		// {
		// 	name: "lgsId",
		// 	nameEn: "lgsId",
		// 	data: 'lgsId',
		// 	// editor: 'select',
		// 	// selectOptions: []
		// 	type: 'text',
		// 	// type: 'dropdown',
		// 	// source: []
		// 	// width: 200,
		// },
		// {
		// 	name: "lgsCode",
		// 	nameEn: "lgsCode",
		// 	data: 'lgsCode',
		// 	type: 'text',
		// 	// type: 'dropdown',
		// 	// source: []
		// },
		{
			name: "地址名称",
			nameEn: "addrName",
			data: 'addrName',
			type: 'text',
		},
		{
			name: "发件人名称",
			nameEn: "name",
			data: 'name',
			type: 'text',
		},
		{
			name: "电话",
			nameEn: "phone",
			data: 'phone',
			type: 'text',
		},
		
		{
			name: "地址一",
			nameEn: "addresses1",
			data: 'addresses1',
			type: 'text',
		},
		{
			name: "地址二",
			nameEn: "addresses2",
			data: 'addresses2',
			type: 'text',
		},
		{
			name: "城市",
			nameEn: "city",
			data: 'city',
			type: 'text',
		},
		
		
		
		
		{
			name: "州",
			nameEn: "provinceCode",
			data: 'provinceCode',
			type: 'text',
		},
		{
			name: "邮编",
			nameEn: "postalCode",
			data: 'postalCode',
			type: 'text',
		},
		{
			name: "国家",
			nameEn: "countryCode",
			data: 'countryCode',
			type: 'text',
			// type: 'dropdown',
			// source: []
		},
		{
			name: "备注",
			nameEn: "remark",
			data: 'remark',
			type: 'text',
		},

		// {
		// 	name: "ID",
		// 	data: 'id',
		// 	type: 'text',
		// 	readOnly: true
		// },
	];
}


export const nestedHeadersTop_config = () => {
	return [
		// [{
		// 		label: '发货信息',
		// 		colspan: 21
		// 	},
		// 	{
		// 		label: '追踪信息(自供面单时必填)tracking Info',
		// 		colspan: 2
		// 	},
		// 	{
		// 		label: '保险和签名服务(只用于平台出单)',
		// 		colspan: 2
		// 	},
		// 	'',
		// 	'',
		// ]
	];
}